import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "date": "2017-06-06T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><a parentName="p" {...{
        "href": "https://raw.abduzeedo.com/post/27409863297"
      }}><img parentName="a" {...{
          "src": "/reading/the-lady-astronaut.jpeg",
          "alt": "Illustration of women in space by Voila Vala"
        }}></img></a></p>
    <ul>
      <li parentName="ul">{`Title: `}<strong parentName="li">{`The Calculating Stars`}</strong>{` and `}<strong parentName="li">{`The Fated Sky`}</strong>{` from The Lady Astronaut series`}</li>
      <li parentName="ul">{`Mary Robinette Kowal`}</li>
    </ul>
    <p>{`My rating: ★★★★`}</p>
    <p>{`Interesting take on alternative history if women had been in the first missions to the moon, and beyond. Probably would class as a "hard science fiction" novel as both books go into very detailed descriptions of planning and being on board space flight. I struggled to connect with the main character a bit, I think because of the time period. Otherwise really enjoyed the books.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      